import React, { useRef, useState } from "react";
import { useIntl } from "gatsby-plugin-intl";
import useCompany from "../hooks/useCompany";

import "../styles/pages/Terms.scss";

import Layout from "../components/Layout.jsx";
import Seo from "../components/Seo";

export default function Terms() {
  const intl = useIntl();
  const company = useCompany();
  const [active, setActive] = useState(0);
  const contentRef = useRef(null);

  const handleTab = (tabSelected) => () => {
    setActive(tabSelected);
    contentRef.current.firstElementChild.scrollTo(0, 0);
    contentRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const tabs = [
    {
      title: intl.formatMessage({ id: "terms-tab1" }),
      text: intl.formatMessage({ id: "terms-text1" })
    },
    {
      title: intl.formatMessage({ id: "terms-tab2" }),
      text: intl.formatMessage({ id: "terms-text2" })
    },
    {
      title: intl.formatMessage({ id: "terms-tab3" }),
      text: intl.formatMessage({ id: "terms-text3" })
    },
    {
      title: intl.formatMessage({ id: "terms-tab4" }),
      text: intl.formatMessage({ id: "terms-text4" })
    },
    {
      title: intl.formatMessage({ id: "terms-tab5" }),
      text: intl.formatMessage({ id: "terms-text5" })
    },
    {
      title: intl.formatMessage({ id: "terms-tab6" }),
      text: intl.formatMessage({ id: "terms-text6" })
    },
    {
      title: intl.formatMessage({ id: "terms-tab7" }),
      text: intl.formatMessage({ id: "terms-text7" })
    },
    {
      title: intl.formatMessage({ id: "terms-tab8" }),
      text: intl.formatMessage({ id: "terms-text8" })
    },
  ];

  const tabActive = tabs[active];

  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: "terms-title" })} />
      <div className="terms">
        <section className="terms__tabs">
          {tabs.map((tab, index) => (
            <button onClick={handleTab(index)} type="button" className="terms__tab" key={`${index + 0}`}>
              <p className="terms__tab-text">{tab.title}</p>
            </button>
          ))}
        </section>
        <section ref={contentRef} className="terms__content">
          {tabActive ? (
            <>
              <h1 className="terms__content-title">{tabActive.title}</h1>
              <p className="terms__content-text">
                {tabActive.text}
                <br />
                <br />
                {company}
              </p>
            </>
          ) : null}
        </section>
      </div>
    </Layout>
  );
}
